import React, { useEffect, useState /*, Suspense*/ } from "react";
import Routes from "./components/core/model/routes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./design/scss/custom.scss";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import SetIcons from "./fontawesome";
import UrlTracker from "components/core/model/UrlTracker";

const App = () => {
  const [isReady, setReady] = useState(false);
  // const [isReady2, setReady2] = useState(false);
  const dispatch = useDispatch();
  const api = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
  });
  const client_id = process.env.REACT_APP_CLIENT_ID_AUTH0;
  //initialise GTM
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  };
  const initialiseGTM = (TagManager) => {
    TagManager.initialize(tagManagerArgs);
    dispatch({ type: "setLoaded" });
  };
  const launchGTM = () => {
    import("react-gtm-module").then((TagManager) => {
      initialiseGTM(TagManager);
    });
  };
  const setGTM = () => {
    setTimeout(function () {
      launchGTM();
    }, 0);
  };

  const setAuth = () => {
    if (!isReady) {
      const queryString = require("query-string");
      const url_parameters = queryString.parse(window.location.search);
      const refreshToken = Cookies.get("cherpasRefresh");
      const accessToken = Cookies.get("cherpasAccess");
      const connectApi = (params, config) => {
        api
          .post("token/", params, config)
          .then((response) => {
            Cookies.set("cherpasRefresh", response.data.refresh_token, {
              expires: 365,
            });
            var date = new Date();
            date.setTime(date.getTime() + response.data.expires_in * 1000);
            Cookies.set("cherpasAccess", response.data.access_token, {
              expires: date,
            });
            dispatch({ type: "connect" });
            setReady(true);
          })
          .catch((error) => {
            dispatch({ type: "disconnect" });
            console.log(error);
            setReady(true);
            Cookies.remove("cherpasRefresh");
            Cookies.remove("cherpasAccess");
            dispatch({ type: "setError" });
          });
      };
      if (!!accessToken) {
        dispatch({ type: "connect" });
        setReady(true);
      } else if (url_parameters.sesame !== undefined) {
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const params = new URLSearchParams();
        params.append("client_id", client_id);
        params.append("code", url_parameters.sesame);
        params.append("grant_type", "authorization_code");
        connectApi(params, config);
      } else if (!!refreshToken) {
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const params = new URLSearchParams();
        params.append("client_id", client_id);
        params.append("refresh_token", refreshToken);
        params.append("grant_type", "refresh_token");
        connectApi(params, config);
      } else {
        setReady(true);
      }
    }
  };
  // const setURL = () => {
  //   if (isReady) {
  //     setTimeout(function () {
  //       setReady2(true);
  //     }, 1000);
  //   }
  // };
  useEffect(() => setAuth());
  // useEffect(() => setURL());
  useEffect(() => setGTM());
  return (
    <div className="App d-flex flex-column">
      <SetIcons />
      {isReady ? <Routes /> : null}
      <UrlTracker />
    </div>
  );
};

export default App;
