const initialState = { servicesJannu: [] };

function servicesReducerJannu(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setServicesJannu":
      nextState = { servicesJannu: action.content };
      return nextState;
    default:
      return state;
  }
}

export default servicesReducerJannu;
