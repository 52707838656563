const initialState = { needRefresh: false, isRefresh: false };

function needRefresh(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "needRefresh":
      nextState = { needRefresh: true, isRefresh: state.isRefresh };
      return nextState;
    case "refreshLaunch":
      nextState = { needRefresh: false, isRefresh: true };
      return nextState;
    case "refreshOk":
      nextState = { needRefresh: state.needRefresh, isRefresh: false };
      return nextState;
    default:
      return state;
  }
}

export default needRefresh;
