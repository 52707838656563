const initialState = { isModal: false };

function modalReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setModalTrue":
      nextState = { isModal: true };
      return nextState;
    case "setModalFalse":
      nextState = { isModal: false };
      return nextState;
    default:
      return state;
  }
}

export default modalReducer;
