const initialState = {
  document_identity: false,
  document_identity2: false,
  bank_document: false,
};

function documentsReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setDocIdentity":
      nextState = {
        document_identity: action.content.bool,
        document_identity2: state.document_identity2,
        bank_document: state.bank_document,
      };
      return nextState;
    case "setDocIdentity2":
      nextState = {
        document_identity: state.document_identity,
        document_identity2: action.content.bool,
        bank_document: state.bank_document,
      };
      return nextState;
    case "setDocBank":
      nextState = {
        document_identity: state.document_identity,
        document_identity2: state.document_identity2,
        bank_document: action.content.bool,
      };
      return nextState;
    default:
      return state;
  }
}

export default documentsReducer;
