const initialState = { connected: false, id: "" };

function connected(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "connect":
      nextState = { connected: true, id: state.id };
      return nextState;
    case "disconnect":
      nextState = { connected: false, id: state.id };
      return nextState;
    default:
      return state;
  }
}

export default connected;
