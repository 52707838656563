const initialState = {};

function targetReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "addTarget":
      var key = action.content.id;
      var obj = {};
      obj[key] = action.content.address;
      nextState = Object.assign(state, obj);
      return nextState;
    default:
      return state;
  }
}

export default targetReducer;
