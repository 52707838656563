import { createStore, combineReducers } from "redux";
import modalReducer from "./reducers/annapurna/modalReducer";
import formsReducer from "./reducers/annapurna/formsReducer";
import servicesReducer from "./reducers/annapurna/servicesReducer";
import documentsReducer from "./reducers/annapurna/documentsReducer";
import servicesReducerJannu from "./reducers/jannu/servicesReducerJannu";
import documentsReducerJannu from "./reducers/jannu/documentsReducerJannu";
import profilReducerJannu from "./reducers/jannu/profilReducerJannu";
import currentShow from "./reducers/jannu/currentShowReducer";
import currentService from "./reducers/jannu/currentServiceReducer";
import collapsed from "./reducers/jannu/collapsedReducer";
import connected from "./reducers/jannu/connectedReducer";
import targetReducer from "./reducers/jannu/targetReducer";
import errorSezameReducer from "./reducers/jannu/errorSezameReducer";
import gtmReducer from "./reducers/GTMReducer";
import refresh from "./reducers/jannu/needRefreshReducer";
import funnelGeneral from "./reducers/sangalila/generalReducer";
import infosFunnel from "./reducers/sangalila/infosFunnelReducer";

const rootReducer = combineReducers({
  modal: modalReducer,
  forms: formsReducer,
  services: servicesReducer,
  documents: documentsReducer,
  servicesJannu: servicesReducerJannu,
  documentsJannu: documentsReducerJannu,
  profilJannu: profilReducerJannu,
  currentShow: currentShow,
  currentService: currentService,
  collapsed: collapsed,
  connected: connected,
  target: targetReducer,
  gtm: gtmReducer,
  errorSezame: errorSezameReducer,
  refresh: refresh,
  funnelGeneral: funnelGeneral,
  infosFunnel: infosFunnel,
});
const store = createStore(rootReducer);

store.subscribe(() => {
  localStorage.setItem(
    "infoServices",
    JSON.stringify(store.getState().forms.services)
  );
});

export default store;
