const initialState = { currentService: [] };

function currentService(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setCurrentService":
      nextState = { currentService: action.content };
      return nextState;
    default:
      return state;
  }
}

export default currentService;
