import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cWfr from "./copywritting/cWfr.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

//it's impossible to put nbsp in this code so we use string.replace(/ /g, "\u00a0") to put a nbsp in every spaces

/* var kabruTxt = kabru.map(function (value, label) {
  return value.replace(/ /g, "\u00a0");
});
 */
const kabru = cWfr.kabru;
const footer = cWfr.footer;
const sangalila = cWfr.sangalila;

const resources = {
  fr: {
    translation: {
      kabru,
      footer,
      sangalila,
    },
  },
};

const CleanFrenchTypography = (str) => {
  return str
    .replaceAll("/ /", "\u00a0")
    .replaceAll("'", "’")
    .replaceAll(" ?", "\u00a0?")
    .replaceAll(" !", "\u00a0!")
    .replaceAll(" »", "\u00a0»")
    .replaceAll("« ", "«\u00a0")
    .replaceAll(" :", "\u00a0:")
    .replaceAll(" €", "\u00a0€");
};

i18n
  .use({
    type: "postProcessor",
    name: "cleanFrenchTypography",
    process: function (value, key, options, translator) {
      // Of course at some point the langage needs to be taken into account.
      return CleanFrenchTypography(value);
    },
  })
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    postProcess: ["cleanFrenchTypography"],
    // react: {
    //   transKeepBasicHtmlNodesFor: ["br", "strong", "i"], // don't convert to <1></1> if simple react elements
    // },
  });

export default i18n;
