import { library } from "@fortawesome/fontawesome-svg-core";

/* import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons"; */

import { faArrowLeft as faArrowLeft_l } from "@fortawesome/pro-light-svg-icons";
import { faPlus as faPlus_r } from "@fortawesome/pro-regular-svg-icons";
import { faHouse as faHouse_l } from "@fortawesome/pro-light-svg-icons";
import { faRouter as faRouter_l } from "@fortawesome/pro-light-svg-icons";
import { faFireAlt as faFireAlt_l } from "@fortawesome/pro-light-svg-icons";
import { faBolt as faBolt_l } from "@fortawesome/pro-light-svg-icons";
import { faMobile as faMobile_l } from "@fortawesome/pro-light-svg-icons";
import { faFolder as faFolder_l } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle as faQuestionCircle_l } from "@fortawesome/pro-light-svg-icons";
import { faCaretRight as faCaretRight_d } from "@fortawesome/pro-duotone-svg-icons";
import { faBars as faBars_d } from "@fortawesome/pro-duotone-svg-icons";
import { faMapMarkerAlt as faMapMarkerAlt_l } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt as faMapMarkerAlt_s } from "@fortawesome/pro-solid-svg-icons";
import { faGift as faGift_s } from "@fortawesome/pro-solid-svg-icons";
import { faChevronCircleRight as faChevronCircleRight_d } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationTriangle as faExclamationTriangle_l } from "@fortawesome/pro-light-svg-icons";
import { faArrowRight as faArrowRight_l } from "@fortawesome/pro-light-svg-icons";
import { faArchive as faArchive_l } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle as faCheckCircle_l } from "@fortawesome/pro-light-svg-icons";
import { faCaretLeft as faCaretLeft_d } from "@fortawesome/pro-duotone-svg-icons";
import { faPowerOff as faPowerOff_l } from "@fortawesome/pro-light-svg-icons";
import { faTachometerSlow as faTachometerSlow_l } from "@fortawesome/pro-light-svg-icons";
import { faEye as faEye_l } from "@fortawesome/pro-light-svg-icons";
import { faUser as faUser_l } from "@fortawesome/pro-light-svg-icons";
import { faSignOut as faSignOut_l } from "@fortawesome/pro-light-svg-icons";
import { faSignOut as faSignOut_s } from "@fortawesome/pro-solid-svg-icons";
import { faMap as faMap_l } from "@fortawesome/pro-light-svg-icons";
import { faOven as faOven_l } from "@fortawesome/pro-light-svg-icons";
import { faPhoneOffice as faPhoneOffice_l } from "@fortawesome/pro-light-svg-icons";
import { faGlobe as faGlobe_l } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle as faUserCircle_l } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle as faUserCircle_s } from "@fortawesome/pro-solid-svg-icons";
import { faAddressCard as faAddressCard_l } from "@fortawesome/pro-light-svg-icons";
import { faAddressCard as faAddressCard_s } from "@fortawesome/pro-solid-svg-icons";
import { faCreditCardFront as faCreditCardFront_l } from "@fortawesome/pro-light-svg-icons";
import { faCreditCardFront as faCreditCardFront_s } from "@fortawesome/pro-solid-svg-icons";
import { faLockAlt as faLockAlt_l } from "@fortawesome/pro-light-svg-icons";
import { faLockAlt as faLockAlt_s } from "@fortawesome/pro-solid-svg-icons";
import { faExclamationCircle as faExclamationCircle_l } from "@fortawesome/pro-light-svg-icons";
import { faArrowLeft as faArrowLeft_d } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowRight as faArrowRight_d } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as faCircle_d } from "@fortawesome/pro-duotone-svg-icons";
import { faPhoneVolume as faPhoneVolume_l } from "@fortawesome/pro-light-svg-icons";
import { faMobileAndroid as faMobileAndroid_l } from "@fortawesome/pro-light-svg-icons";
import { faHourglassHalf as faHourglassHalf_l } from "@fortawesome/pro-light-svg-icons";
import { faTimesCircle as faTimesCircle_l } from "@fortawesome/pro-light-svg-icons";
import { faChevronRight as faChevronRight_l } from "@fortawesome/pro-light-svg-icons";
import { faChevronCircleRight as faChevronCircleRight_l } from "@fortawesome/pro-light-svg-icons";
import { faEdit as faEdit_l } from "@fortawesome/pro-light-svg-icons";
import { faMoneyCheck as faMoneyCheck_l } from "@fortawesome/pro-light-svg-icons";
import { faShieldCheck as faShieldCheck_l } from "@fortawesome/pro-light-svg-icons";
import { faTachometerAltSlowest as faTachometerAltSlowest_l } from "@fortawesome/pro-light-svg-icons";
import { faLinkedin as faLinkedin_b } from "@fortawesome/free-brands-svg-icons";
import { faGoogle as faGoogle_b } from "@fortawesome/free-brands-svg-icons";
import { faFacebook as faFacebook_b } from "@fortawesome/free-brands-svg-icons";
import { faFacebookMessenger as faFacebookMessenger_b } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp as faWhatsapp_b } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope as faEnvelope_l } from "@fortawesome/pro-light-svg-icons";
import { faCloudUpload as faCloudUpload_l } from "@fortawesome/pro-light-svg-icons";
import { faPlusCircle as faPlusCircle_l } from "@fortawesome/pro-light-svg-icons";
import { faTrashAlt as faTrashAlt_l } from "@fortawesome/pro-light-svg-icons";
import { faTimes as faTimes_l } from "@fortawesome/pro-light-svg-icons";
import { faUndoAlt as faUndoAlt_l } from "@fortawesome/pro-light-svg-icons";
import { faCheck as faCheck_s } from "@fortawesome/pro-solid-svg-icons";
import { faCommentDots as faCommentDots_d } from "@fortawesome/pro-duotone-svg-icons";
import { faSpinnerThird as faSpinnerThird_d } from "@fortawesome/pro-duotone-svg-icons";
import { faBolt as faBolt_s } from "@fortawesome/pro-solid-svg-icons";
import { faFireAlt as FireAlt_s } from "@fortawesome/pro-solid-svg-icons";
import { faRouter as faRouter_s } from "@fortawesome/pro-solid-svg-icons";
import { faMobile as faMobile_s } from "@fortawesome/pro-solid-svg-icons";
import { faLanguage as faLanguage_s } from "@fortawesome/pro-solid-svg-icons";
import { faShieldCheck as faShieldCheck_s } from "@fortawesome/pro-solid-svg-icons";
import { faSearch as faSearch_s } from "@fortawesome/pro-solid-svg-icons";
import { faPen as faPen_l } from "@fortawesome/pro-light-svg-icons";
import { faPen as faPen_r } from "@fortawesome/pro-regular-svg-icons";
import { faArrowCircleRight as faArrowCircleRight_l } from "@fortawesome/pro-light-svg-icons";
import { faCircle as faCircle_s } from "@fortawesome/pro-solid-svg-icons";
import { faRocketLaunch as faRocketLaunch_s } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as faCheckCircle_s } from "@fortawesome/pro-solid-svg-icons";
import { faArrowLeft as faArrowLeft_s } from "@fortawesome/pro-solid-svg-icons";
import { faInfoCircle as faInfoCircle_r } from "@fortawesome/pro-regular-svg-icons";
import { faChevronCircleDown as faChevronCircleDown_r } from "@fortawesome/pro-regular-svg-icons";
import { faChevronCircleRight as faChevronCircleRight_r } from "@fortawesome/pro-regular-svg-icons";
import { faRabbitFast as faRabbitFast_l } from "@fortawesome/pro-light-svg-icons";

// to use them in other files :
//    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//    <FontAwesomeIcon icon={["fal", "check-circle"]} />

library.add(
  faArrowLeft_l,
  faPlus_r,
  faHouse_l,
  faRouter_l,
  faFireAlt_l,
  faBolt_l,
  faMobile_l,
  faFolder_l,
  faQuestionCircle_l,
  faCaretRight_d,
  faBars_d,
  faMapMarkerAlt_l,
  faChevronCircleRight_d,
  faExclamationTriangle_l,
  faArrowRight_l,
  faArchive_l,
  faCheckCircle_l,
  faCaretLeft_d,
  faPowerOff_l,
  faTachometerSlow_l,
  faEye_l,
  faUser_l,
  faSignOut_l,
  faSignOut_s,
  faMap_l,
  faOven_l,
  faPhoneOffice_l,
  faGlobe_l,
  faUserCircle_l,
  faAddressCard_l,
  faCreditCardFront_l,
  faLockAlt_l,
  faUserCircle_s,
  faAddressCard_s,
  faCreditCardFront_s,
  faMapMarkerAlt_s,
  faLockAlt_s,
  faExclamationCircle_l,
  faArrowLeft_d,
  faArrowRight_d,
  faCircle_d,
  faPhoneVolume_l,
  faMobileAndroid_l,
  faHourglassHalf_l,
  faTimesCircle_l,
  faChevronRight_l,
  faChevronCircleRight_l,
  faEdit_l,
  faMoneyCheck_l,
  faShieldCheck_l,
  faTachometerAltSlowest_l,
  faLinkedin_b,
  faEnvelope_l,
  faGoogle_b,
  faFacebook_b,
  faFacebookMessenger_b,
  faWhatsapp_b,
  faCloudUpload_l,
  faPlusCircle_l,
  faTrashAlt_l,
  faTimes_l,
  faUndoAlt_l,
  faCheck_s,
  faCommentDots_d,
  faSpinnerThird_d,
  faBolt_s,
  FireAlt_s,
  faRouter_s,
  faMobile_s,
  faLanguage_s,
  faShieldCheck_s,
  faSearch_s,
  faPen_l,
  faPen_r,
  faArrowCircleRight_l,
  faCircle_s,
  faRocketLaunch_s,
  faCheckCircle_s,
  faArrowLeft_s,
  faInfoCircle_r,
  faGift_s,
  faChevronCircleDown_r,
  faRabbitFast_l,
  faChevronCircleRight_r
);

const SetIcons = () => {
  return null;
};

export default SetIcons;
