const initialState = { services: [] };

function servicesReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setServices":
      nextState = { services: action.content };
      return nextState;
    default:
      return state;
  }
}

export default servicesReducer;
