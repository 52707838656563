const initialState = {
  postalCode: null,
  service: "",
  bat: "",
  heat: "",
  email: "",
  name: "",
  fibre: "",
  age: "",
  liste: null,
  address: "",
  provider: "",
  price: null,
  mobileData: "",
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function infosFunnel(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setPostalCodeSangalila":
      nextState = { ...state };
      nextState.postalCode = action.content;
      return nextState;
    case "setServiceSangalila":
      nextState = { ...state };
      nextState.service = action.content;
      return nextState;
    case "setBatSangalila":
      nextState = { ...state };
      nextState.bat = action.content;
      return nextState;
    case "setHeatSangalila":
      nextState = { ...state };
      nextState.heat = action.content;
      return nextState;
    case "setEmailSangalila":
      nextState = { ...state };
      nextState.email = action.content;
      return nextState;
    case "setNameSangalila":
      nextState = { ...state };
      nextState.name = capitalizeFirstLetter(action.content);
      return nextState;
    case "setAgeSangalila":
      nextState = { ...state };
      nextState.age = action.content;
      return nextState;
    case "setFibreSangalila":
      nextState = { ...state };
      nextState.fibre = action.content;
      return nextState;
    case "setListeSangalila":
      nextState = { ...state };
      nextState.liste = action.content;
      return nextState;
    case "setAddressSangalila":
      nextState = { ...state };
      nextState.address = action.content;
      return nextState;
    case "setProviderSangalila":
      nextState = { ...state };
      nextState.provider = action.content;
      return nextState;
    case "setPriceSangalila":
      nextState = { ...state };
      nextState.price = action.content;
      return nextState;
    case "setDataSangalila":
      nextState = { ...state };
      nextState.mobileData = action.content;
      return nextState;
    default:
      return state;
  }
}

export default infosFunnel;
