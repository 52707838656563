const initialState = localStorage.getItem("infoServices")
  ? { services: JSON.parse(localStorage.getItem("infoServices")) }
  : { services: [] };

function formsReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setRio":
      nextState = { ...state };
      var index = nextState.services.findIndex(
        (x) => x.pk === action.content.pk
      );
      if (index < 0) {
        nextState.services.push({
          pk: action.content.pk,
          rio: action.content.rio,
          pto: "",
          comment: "",
        });
      } else {
        nextState.services[index].pk = state.services[index].pk;
        nextState.services[index].rio = action.content.rio;
        nextState.services[index].pto = state.services[index].pto;
        nextState.services[index].comment = state.services[index].comment;
      }
      return nextState;
    case "setPto":
      nextState = { ...state };
      var index2 = nextState.services.findIndex(
        (x) => x.pk === action.content.pk
      );
      if (index2 < 0) {
        nextState.services.push({
          pk: action.content.pk,
          rio: "",
          pto: action.content.pto,
          comment: "",
        });
      } else {
        nextState.services[index2].pk = state.services[index2].pk;
        nextState.services[index2].rio = state.services[index2].rio;
        nextState.services[index2].pto = action.content.pto;
        nextState.services[index2].comment = state.services[index2].comment;
      }
      return nextState;
    case "setComment":
      nextState = { ...state };
      var index3 = nextState.services.findIndex(
        (x) => x.pk === action.content.pk
      );
      if (index3 < 0) {
        nextState.services.push({
          pk: action.content.pk,
          rio: "",
          pto: "",
          comment: action.content.comment,
        });
      } else {
        nextState.services[index3].pk = state.services[index3].pk;
        nextState.services[index3].rio = state.services[index3].rio;
        nextState.services[index3].pto = state.services[index3].pto;
        nextState.services[index3].comment = action.content.comment;
      }
      return nextState;
    default:
      return state;
  }
}

export default formsReducer;
