const initialState = { isLoaded: false, waitList: [] };

function gtmReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setLoaded":
      if (!state.isLoaded) {
        if (state.waitList !== []) {
          state.waitList.forEach((elem) => {
            window.dataLayer.push(elem);
          });
        }
        nextState = { isLoaded: true, waitList: [] };
      } else {
        nextState = { isLoaded: true, waitList: [] };
      }
      return nextState;
    case "setDataLayer":
      if (state.isLoaded) {
        window.dataLayer.push(action.content);
        nextState = { isLoaded: true, waitList: [] };
      } else {
        let newWaitList = state.waitList;
        newWaitList.push(action.content);
        nextState = { isLoaded: state.isLoaded, waitList: newWaitList };
      }
      return nextState;
    default:
      return state;
  }
}

export default gtmReducer;
