const initialState = { currentShow: "dashboard" };

function currentShow(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setCurrentShow":
      window.history.pushState(action.content, null, null);
      window.scrollTo(0, 0);
      nextState = { currentShow: action.content };
      return nextState;
    default:
      return state;
  }
}

export default currentShow;
