const initialState = { isError: false, codeError: "" };

function errorSezame(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setError":
      nextState = { isError: true, codeError: state.codeError };
      return nextState;
    case "closeError":
      nextState = { isError: false, codeError: state.codeError };
      return nextState;
    case "setCodeError":
      nextState = { isError: state.isError, codeError: action.content };
      return nextState;
    default:
      return state;
  }
}

export default errorSezame;
