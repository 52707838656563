const initialState = { documentsJannu: [] };

function documentsReducerJannu(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setDocumentsJannu":
      nextState = { documentsJannu: action.content };
      return nextState;
    default:
      return state;
  }
}

export default documentsReducerJannu;
