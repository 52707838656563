const initialState = { collapsed: true };

function collapsed(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setCollapsed":
      nextState = { collapsed: !state.collapsed };
      return nextState;
    default:
      return state;
  }
}

export default collapsed;
