const initialState = { profilJannu: [] };

function profilReducerJannu(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setProfilJannu":
      nextState = { profilJannu: action.content };
      return nextState;
    default:
      return state;
  }
}

export default profilReducerJannu;
