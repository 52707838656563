import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
//import of the differents pages
/* import Home from "../../../pages/public/kabru/Home"; */
/* import CheckBill from "../../../pages/public/kabru/CheckBill"; */
/* import AboutUs from "../../../pages/public/kabru/AboutUs"; */
/* import Terms from "../../../pages/public/kabru/Terms"; */
/* import HowItWork from "../../../pages/public/kabru/HowItWorks"; */
/* import NotFound from "../../../pages/public/kabru/NotFound"; */
/* import MemberArea from "../../../pages/public/jannu/MemberArea"; */
/* import LegalNotice from "../../../pages/public/kabru/LegalNotice"; */
/* import Confidentiality from "../../../pages/public/kabru/Confidentiality"; */
/* import VideoTestimony from "../../../pages/public/kabru/VideoTestimony"; */
/* import Connexion from "../../../pages/public/jannu/Connexion"; */
/* import Embarquement from "../Embarquement"; */

const Home = lazy(() => import("../../../pages/public/kabru/Home"));
const LpQuizz = lazy(() => import("../../../pages/public/kabru/LpQuizz"));
const Electricity = lazy(() =>
  import("../../../pages/public/kabru/Electricity")
);
const Internet = lazy(() => import("../../../pages/public/kabru/Internet"));
const Mobile = lazy(() => import("../../../pages/public/kabru/Mobile"));
const MemberArea = lazy(() => import("../../../pages/public/jannu/MemberArea"));

const CheckBill = lazy(() =>
  import("../../../pages/public/sangalila/CheckBill")
);
const AboutUs = lazy(() => import("../../../pages/public/kabru/AboutUs"));
const Terms = lazy(() => import("../../../pages/public/kabru/Terms"));
const HowItWork = lazy(() => import("../../../pages/public/kabru/HowItWorks"));
const Connexion = lazy(() => import("../../../pages/public/jannu/Connexion"));
const LegalNotice = lazy(() =>
  import("../../../pages/public/kabru/LegalNotice")
);
const Confidentiality = lazy(() =>
  import("../../../pages/public/kabru/Confidentiality")
);
const VideoTestimony = lazy(() =>
  import("../../../pages/public/kabru/VideoTestimony")
);

const Embarquement = lazy(() => import("../Embarquement"));

const NotFound = lazy(() => import("../../../pages/public/kabru/NotFound"));

const Routes = () => {
  const isConnected = useSelector((state) => state.connected).connected;
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={null}>
              <section>
                {isConnected ? (
                  <Suspense fallback={null}>
                    <MemberArea />
                  </Suspense>
                ) : (
                  <Suspense fallback={null}>
                    <Home />
                  </Suspense>
                )}
              </section>
            </Suspense>
          </Route>
          <Route path="/commencer">
            <Suspense fallback={null}>
              <CheckBill />
            </Suspense>
          </Route>
          <Route exact path="/electricite-gaz">
            <Suspense fallback={null}>
              <Electricity />
            </Suspense>
          </Route>
          <Route exact path="/internet">
            <Suspense fallback={null}>
              <Internet />
            </Suspense>
          </Route>
          <Route exact path="/mobile">
            <Suspense fallback={null}>
              <Mobile />
            </Suspense>
          </Route>
          <Route exact path="/cgu">
            <Suspense fallback={null}>
              <Terms />
            </Suspense>
          </Route>
          <Route exact path="/equipe">
            <Suspense fallback={null}>
              <AboutUs />
            </Suspense>
          </Route>
          <Route exact path="/comment-ca-marche">
            <Suspense fallback={null}>
              <HowItWork />
            </Suspense>
          </Route>
          <Route exact path="/mentions-legales">
            <Suspense fallback={null}>
              <LegalNotice />
            </Suspense>
          </Route>
          <Route exact path="/confidentialite">
            <Suspense fallback={null}>
              <Confidentiality />
            </Suspense>
          </Route>
          <Route exact path="/temoignages">
            <Suspense fallback={null}>
              <VideoTestimony />
            </Suspense>
          </Route>
          <Route exact path="/connexion">
            <Suspense fallback={null}>
              <Connexion />
            </Suspense>
          </Route>
          <Route exact path="/bienvenue">
            <Suspense fallback={null}>
              <LpQuizz />
            </Suspense>
          </Route>
          <Route path="/embarquement/">
            <Suspense fallback={null}>
              <Embarquement />
            </Suspense>
          </Route>
          <Route path="*">
            <Suspense fallback={null}>
              <NotFound />
            </Suspense>
          </Route>
        </Switch>
      </Router>
    </>
  );
};
export default Routes;
